<template>
  <div class="content">
    <div class="menu">
      <router-link
        :to="item.link"
        v-for="(item, index) in data.navList"
        :key="index"
      >
        <img :src="routePath == item.link ? item.imgChecked : item.img" />
      </router-link>
    </div>
    <div class="warp">
      <div class="title">
        <img src="../assets/images/enterprise/message.png" />
        <div class="text">
          您当前有
          <span style="padding: 0 15px" v-loading="loading">
            {{ messageCount }}
          </span>
          份文档未接收
        </div>
      </div>
      <div class="StylesToChoose" v-if="userType == 'painter'">
        <StylesToChoose
          @submit="stylesToChooseSubmit"
          ref="stylesToChoose"
        ></StylesToChoose>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import StylesToChoose from "./StylesToChoose";
export default {
  components: { StylesToChoose },
  name: "MenuNav",
  props: {
    data: Object,
  },
  data() {
    return {
      routePath: "",
      messageCount: "",
      loading: true,
    };
  },
  watch: {
    //监听路由变化
    "$route.path": {
      immediate: true,
      handler(newVal) {
        this.routePath = newVal;
      },
    },
  },
  mounted() {
    this.getProjectDocumentList();
  },
  methods: {
    // 项目文案列表
    getProjectDocumentList() {
      let data = {
        userType: this.userType,
        state: 0,
      };
      this.loading = true;
      this.$api.projectDocument.list(data).then((res) => {
        this.messageCount = res.data.length;
        this.loading = false;
      }).catch(() => {});
    },
    // 提交用户风格
    stylesToChooseSubmit(checkedIds) {
      this.$api.user
        .set_style({ styles_ids: checkedIds.join(",") })
        .then(() => {
          this.$refs.stylesToChoose.stylesToChoose = false;
        }).catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 0 5%;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  .menu {
    background: url(../assets/images/enterprise/menu.png) no-repeat;
    background-size: auto 100%;
    display: flex;
    flex-direction: column;
    padding: 6% 4%;
    img {
      width: 4vw;
      margin-bottom: 1vw;
      cursor: pointer;
    }
  }
  .warp {
    width: 90%;
    .title {
      display: flex;
      align-items: center;
      margin-left: 15%;
      margin-top: 5px;
      margin-bottom: 3vw;
      .text {
        margin-left: 1%;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }
    }
    .StylesToChoose {
      position: relative;
    }
  }
}
</style>
