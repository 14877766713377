<template>
  <div
    class="header"
    :style="{
      background: 'url(' + data.background + ') no-repeat',
      backgroundSize: '100%',
    }"
  >
    <div class="go-home" @click="goHome">回到首页</div>
    <div class="left">
      <div class="userinfo" v-if="isLogin">
        <span class="nickname"> 欢迎您：{{ vuex_userinfo.id }} </span>
        <span class="logout" @click="logout"> 退出登录 </span>
      </div>
      <div v-else>
        <img
          src="../assets/images/enterprise/login.png"
          @click="
            memberType = 'login';
            $ku.vuex('vuex_login_member_show', true);
          "
        />
        <img
          src="../assets/images/enterprise/register.png"
          @click="
            memberType = 'register';
            $ku.vuex('vuex_login_member_show', true);
          "
        />
      </div>
    </div>
    <div class="right">
      <router-link
        :to="item.link"
        class="right"
        v-for="(item, index) in data.rightButtons"
        :key="index"
      >
        <img :src="routePath == item.link ? item.imgChecked : item.img" />
      </router-link>
    </div>
    <Login v-show="vuex_login_member_show"></Login>
  </div>
</template>

<script>
import Login from "@/components/Login.vue"
export default {
  components: {
    Login,
  },
  name: "RoleHeader",
  props: {
    data: Object,
  },
  data() {
    return {
      routePath: "",
      memberType: "login",
      form: {},
      codeText: "获取验证码",
      codeState: 0,
      timer: "",
      loginType: "password",
      loginTypeText: "验证码登录",
    };
  },
  watch: {
    //监听路由变化
    "$route.path": {
      immediate: true,
      handler(newVal) {
        this.routePath = newVal;
      },
    },
  },
  mounted() {
    if (this.userType == "painter") {
      // 调用合同组件内的-是否签署合同接口
      this.$parent.$refs.contract.getIsContract();
    }
  },
  methods: {
    loginTypeChange() {
      this.loginType == "password"
        ? ((this.loginType = "code"), (this.loginTypeText = "密码登录"))
        : ((this.loginType = "password"), (this.loginTypeText = "验证码登录"));
    },
    // 登录注册提交
    memberButtonClick() {
      this.form.userType = this.userType;
      if (this.loginType == "code") {
        this.$api.user.mobilelogin(this.form).then((res) => {
          this.$ku.vuex("isLogin", true);
          this.$ku.vuex("vuex_userinfo", res.data.userinfo);
          this.$ku.vuex("vuex_login_member_show", false);
          location.reload();
        });
      } else {
        this.$api.user[this.memberType](this.form).then((res) => {
          this.$ku.vuex("isLogin", true);
          this.$ku.vuex("vuex_userinfo", res.data.userinfo);
          this.$ku.vuex("vuex_login_member_show", false);
          location.reload();
        });
      }
    },
    // 获取验证码
    getCode(event) {
      if (this.codeState == 1) {
        return;
      }
      this.codeText = "验证码发送中";
      this.codeState = 1;
      let data = {
        mobile: this.form.mobile,
        event,
      };
      this.$api.sms
        .send(data)
        .then((res) => {
          let timerVal = 60;
          this.timer = setInterval(() => {
            if (timerVal <= 0) {
              clearInterval(this.timer);
              this.codeState = 0;
              this.codeText = "获取验证码";
              return;
            }
            timerVal--;
            this.codeText = res.msg + `，请${timerVal}秒后重试`;
          }, 1000);
        })
        .catch(() => {
          this.codeText = "获取验证码";
          this.codeState = 0;
        });
    },
    // 退出登录
    logout() {
      this.$api.user.logout().then(() => {
        this.$ku.vuex("isLogin", false);
        this.$ku.vuex("vuex_userinfo", {});
      });
    },
    goHome() {
      this.$router.push("/");
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.header {
  background-size: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5% 5% 0 13.13%;
  .go-home {
    position: absolute;
    left: 1vw;
    top: 1vw;
    color: #fff793;
    font-size: 14px;
    cursor: pointer;
  }
  .left {
    display: flex;
    flex-wrap: wrap;
    .userinfo {
      display: flex;
      align-items: center;
      .avatar {
        width: 3vw;
        height: 3vw;
        img {
          width: 100%;
          border-radius: 100px;
        }
      }
      .nickname {
        font-size: 1vw;
        color: #ffffff;
      }
      .logout {
        background: url(../assets/images/painter/title-bg.png) no-repeat;
        background-size: 100% 100%;
        padding: 0.5vw 1vw;
        margin-left: 1vw;
        cursor: pointer;
        font-weight: 800;
        font-size: 1vw;
      }
    }
    img {
      margin-right: 3.13vw;
      width: 6vw;
      cursor: pointer;
    }
  }
  .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    img {
      margin-right: -3%;
      width: 10vw;
      cursor: pointer;
    }
  }

  .member {
    position: absolute;
    left: 50%;
    top: 15vw;
    transform: translateX(-30%);
    width: 20vw;
    background: url(../assets/images/member-bg.png) no-repeat;
    background-size: 100% 100%;
    padding: 4% 2%;
    z-index: 9999;
    .checkbox {
      display: flex;
      justify-content: center;
      font-size: 1.1vw;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      .anniu {
        margin: 0 1vw;
        cursor: pointer;
      }
      .checked {
        color: #ffed34;
        border-bottom: 1px solid #ffed34;
      }
    }
    .RoleHeader-inputs {
      margin: 2vw 0;
      .code {
        font-size: 0.5vw;
        cursor: pointer;
        line-height: 2.5vw;
        .code-span {
          border: 1px solid #ffffff;
          border-radius: 12px;
          padding: 0.2vw 0.5vw;
        }
      }
      .login-type {
        color: #fff;
        font-size: 0.5vw;
        text-align: right;
        cursor: pointer;
      }
    }
    .button {
      width: 10vw;
      margin: 0 auto;
      margin-top: 1vw;
      cursor: pointer;
      img {
        width: 100%;
      }
    }
  }
}
</style>
